/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;

    scrollbar-color: #e8431c white;
    scrollbar-width: thin;

}
.snackBar {
    color: black !important;
    background-color: white !important;
    border: none !important;
}

.snackBarWarn {
    color: red;
    background-color: white;
    border: none;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background-color: white !important;
}

.error-snackbar {
    background-color: #e8431c; /* Rouge pour les erreurs */
    color: black;
  }

